import React, { useState } from 'react';
import Layout from '../../components/layout';
import ServiceFooter from '../../components/ServiceFooter';
import { Col, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';
import { imagePathURL } from '../../utils/JSONData';
import dotImg from '../../Assets/images/Home/dotImg.png';
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';
import "../../Assets/styles/services.scss";
import { stacks } from '../../Assets/images/stack';
import Scrolltop from '../../components/Scrolltotop/scrolltop';
import Footer from '../../components/Footer/Footer';

const Ionic = ({ data }) => {

  const ionicServices = [
    {
      id: 0,
      title: 'Ionic App Consultation',
      details: 'Our Ionic consultants start from the ground up to meet your unique app development needs, providing comprehensive guidance and expertise.',
      image: "https://admin.madhavtechno.com/uploads/Consultation_a6fcb3e606.png"
    },
    {
      id: 1,
      title: 'Ionic Migration Services',
      details: 'Easily upgrade your existing applications to Ionic, harnessing the power of Ionic mobile app development for enhanced performance.',
      image: "https://admin.madhavtechno.com/uploads/Development_781bf6f65c.png"
    },
    {
      id: 2,
      title: 'Ionic Resource Augmentation',
      details: 'Tap into the skills of dedicated Ionic developers to build agile and secure mobile applications that align with your vision.',
      image: "https://admin.madhavtechno.com/uploads/Development_1b899a311e.png"
    },
    {
      id: 3,
      title: 'Ionic App Developmen',
      details: 'Leverage the expertise of our seasoned Ionic developers to create high-performance, cross-platform native mobile apps that exceed your expectations.',
      image: "https://admin.madhavtechno.com/uploads/UI_Design_410d03b060.png"
    },
    {
      id: 4,
      title: 'Ionic UI Design',
      details: 'Craft captivating UI designs with rich features through our Ionic development services, delivering interactive user experiences.',
      image: "https://admin.madhavtechno.com/uploads/Resource_9f81998013.png"
    },
    {
      id: 5,
      title: 'Ionic Maintenance and Support',
      details: 'As a top Ionic app development company, we provide ongoing error handling and maintenance services post-deployment, ensuring a seamless user experience.',
      image: "https://admin.madhavtechno.com/uploads/Maintenance_48c82ee210.png"
    }
  ]

  const ionictechnologyStack = [
    {
      id: 2,
      title: 'Front-end',
      image: 'https://admin.madhavtechno.com/uploads/Frontend_08a71e145a.png',
      dataArr: [
        // { name: 'Reusable Components', image: 'Reusable Components' },
        // { name: 'Routing', image: 'Routing' },
        // { name: 'UI Framework', image: 'UI Framework' },
        // { name: 'Theming', image: 'Theming' },
        { name: 'Cordova Plugins', image: stacks.CordovaPlugins },
        { name: 'Capacitor', image: stacks.capacitor },
        { name: 'Reactive UI', image: stacks.ReactiveUI },
        // { name: 'Animations', image: 'Animations' },
        { name: 'PWA Support', image: stacks.PWASupport },
        { name: 'Cross-Platform', image: stacks.CrossPlatform },
        { name: 'Ionic Native', image: stacks.IonicNative }
      ]
    },
    {
      id: 3,
      title: 'Testing',
      image: 'https://admin.madhavtechno.com/uploads/Testing_7725967b2e.png',
      dataArr: [
        { name: 'Karma Test Runner', image: stacks.Karma },
        { name: 'Jasmine Framework', image: stacks.Jasmine },
        { name: 'Cypress', image: stacks.Cypress },
        { name: 'Playwright', image: stacks.Playwright },
        { name: 'Jest Framework', image: stacks.Jest },
        { name: 'Protractor', image: stacks.Protractor },
        { name: 'Selenium', image: stacks.Selenium },
        { name: 'Appium', image: stacks.appium }
      ]
    },
    {
      id: 4,
      title: 'Database',
      image: 'https://admin.madhavtechno.com/uploads/Database_de87ff3bcc.png',
      dataArr: [
        { name: 'SQlite', image: stacks.SQlite },
        { name: 'MongoDB', image: stacks.MongoDB },
        { name: 'PostgreSQL', image: stacks.PostgreSQL },
        { name: 'Microsoft SQL', image: stacks.MicrosoftSQL },
        { name: 'CoreData (for iOS)', image: stacks.CoreData },
        { name: 'Realm', image: stacks.Realm },
        { name: 'Firebase', image: stacks.firebase }
      ]
    },
    {
      id: 1,
      title: 'Others',
      image: 'https://admin.madhavtechno.com/uploads/Others_2f8e35a9b8.png',
      // dataArr: ['Trello', 'RESTful API', 'BLE', 'Jira', 'Socket.IO', 'Slack', 'Redux', 'GraphQL', 'Third Party API/SDK Integration']
      dataArr: [
        { name: 'Figma', image: stacks.Figma },
        { name: 'Git', image: stacks.Git },
        { name: 'Jira', image: stacks.Jira },
        { name: 'Trello', image: stacks.Trello },
        { name: 'BLE', image: stacks.BLE },
        { name: 'Socket.IO', image: stacks.SocketIO },
        { name: 'Slack', image: stacks.Slack },
        { name: 'GraphQL', image: stacks.GraphQL }
      ]
    },
    {
      id: 5,
      title: 'Cloud & DevOps',
      image: 'https://admin.madhavtechno.com/uploads/Clouddevops_d86784a818.png',
      dataArr: [
        { name: 'AWS', image: stacks.AWS },
        { name: 'Google Cloud', image: stacks.GoogleCloud },
        { name: 'Azure', image: stacks.Azure },
        { name: 'Docker', image: stacks.Docker },
        { name: 'Kubernetes', image: stacks.Kubernetes },
        { name: 'CI/CD', image: stacks.CICD },
        { name: 'Jenkins', image: stacks.Jenkins },
        { name: 'Heroku', image: stacks.Heroku }
      ]
    },
    // {
    //   id: 0,
    //   title: 'Backend',
    //   image: 'https://admin.madhavtechno.com/uploads/Backend_a781633728.png',
    //   dataArr: ['.Net', 'PHP', 'Java', 'Node', 'Ruby on Rails']
    // },
  ]

  // const { allServices, allServiceDevelopmentServices, allTechnologyStacks, allGetInTouchs } = data

  const [selectedStack, setSelectedStack] = useState(0);

  const handleStackClick = (index) => {
    setSelectedStack(index);
  };

  return (
    <>
      <Layout>
        <div className="service_react_group">
          <Row>
            <Col sm={12} md={8} className="m-auto">
              <div>
                <div className="service_react_title">
                  {/* {allServices.nodes[0].data.attributes.title} */}
                  Ionic App Development Company India
                </div>
                <div className="service_react_subtitle">
                  {/* {allServices.nodes[0].data.attributes.description1} */}
                  Harness the power of Ionic for developing highly responsive mobile apps.
                </div>
                <div className="service_react_subtitle2">
                  {/* {allServices.nodes[0].data.attributes.description2} */}
                  Ionic development is all about delivering top-notch user experiences, and we're here to make that happen for your app projects.
                  Our Ionic experts leverage the framework's capabilities to create highly responsive and feature-rich cross-platform applications.
                  Whether you're launching a mobile app or a progressive web app (PWA), Ionic ensures your users are engaged and delighted.
                </div>
                <div className="service_react_button">
                  <button className="service_freequote">GET A FREE QUOTE</button>
                  <button className="service_ourwork">OUR WORK</button>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div>
                <Pulse>
                  {/* <img src={imagePathURL + allServices.nodes[0].data.attributes.image.data.attributes.url} alt="react-logo" /> */}
                  <img src={'https://www.svgrepo.com/show/353912/ionic-icon.svg'} alt="react-logo" />
                </Pulse>
              </div>
            </Col>
          </Row>
        </div>
        <div className="position-relative">
          <div className="service_react_title px-4 text-center">
            {/* {allServices.nodes[0].data.attributes.section1Title} */}
            Ionic App Development Services
          </div>
          <div className="service_react_subtitle px-4 text-center">
            {/* {allServices.nodes[0].data.attributes.section1Desc} */}
            Elevate your business with top-notch Ionic App Development Services
          </div>
          <div className="service_cross-platform">Cross Platform</div>
          <img src={dotImg} alt="dot" className="service_dot-image" />
          {/* <div className="service_RN_develop">
            {allServiceDevelopmentServices.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_develop_group" key={i}>
                    <img className="service_developimg" src={imagePathURL + item?.attributes.image.data.attributes.url} alt="searvice" />
                    <div className="service_developtitle">{item.attributes.title}</div>
                    <div className="service_developdetails">{item.attributes?.description}</div>
                  </div>
                </Fade>
              );
            })}
          </div> */}
          <div className="service_RN_develop">
            {ionicServices.map((item, i) => {
              return (
                <Fade top key={i}>
                  <div className="service_develop_group">
                    {/* <img className="service_developimg" src={item.image} alt="service" /> */}
                    <div className="service_developtitle">{item.title}</div>
                    <div className="service_developdetails">{item.details}</div>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
        <div className="service_quote_group">
          <div className="service_title_group">
            <div className="service_quote_title">
              {/* {allGetInTouchs.nodes[0].data.attributes.title} */}
              Would you like to get in touch with us?
            </div>
            <div className="service_quote_details">
              {/* {allGetInTouchs.nodes[0].data.attributes.description} */}
              Please send us your React Native App Development requirement by simply clicking the Get a Free Quote Button.
            </div>
          </div>
          <div>
            <button className="service_quote_button">
              {/* {allGetInTouchs.nodes[0].data.attributes.btnTxt} */}
              GET A FREE QUOTE
            </button>
          </div>
        </div>
        <div className="service_trofi_content">
          <div className="service_react_title text-center mx-2">
            {/* {allServices.nodes[0].data.attributes.section2Title} */}
            Customized Ionic Applications
          </div>
          <div
            className="service_react_subtitle text-center mx-2"
          >
            {/* {allServices.nodes[0].data.attributes.section2Subtitle} */}
            Our Ionic developers team can accelerate your development process and enhance user experience
          </div>
          <div className="service_trofi_subcontent">
            <Row>
              <Col sm={12} md={6} className="m-auto">
                <div className="service_trofi_title_content">
                  <div className="service_trofi_title">
                    {/* {allServices.nodes[0].data.attributes.section2ContentTitle} */}
                    Trofi: Earn Interest on Crypto App
                  </div>
                  <div className="service_developdetails">
                    {/* {allServices.nodes[0].data.attributes.section2ContentSubTitle} */}
                    Mobile Application Development
                  </div>
                  <div className="service_trofi_details">
                    {/* {allServices.nodes[0].data.attributes.section2ContentDesc} */}
                    Earn interest with all-in-one wealth management app for your crypto assets. Swap or buy crypto, and make passive income on crypto investment.Are you looking for a multi-purpose crypto wealth management app to help you gain better control over your crypto assets? Would you like to make crypto investment and make passive income from interest on your investments? If that is the case, this licensed and regulated crypto platform is the perfect place for you to get started. Buy crypto or swap crypto assets, get crypto loan and earn crypto currency from your investments with one of the best crypto apps available online. This crypto currency app lets you deposit your currency for short term or long term, so you can get better interests and earn crypto income easily.
                  </div>
                  <Fade top>
                    {/* <button className="service_viewCaseStudy"> {allServices.nodes[0].data.attributes.section2ContentBtnTxt}</button> */}
                    <button className="service_viewCaseStudy">VIEW CASE STUDY</button>
                  </Fade>
                </div>
              </Col>
              <Col sm={12} md={6} className="m-auto text-center">
                <div>
                  <Pulse>
                    <img
                      className="service_trofiDevice_img"
                      // src={imagePathURL + allServices.nodes[0].data.attributes.section2ContentImage.data.attributes.url}
                      src={imagePathURL + '/uploads/Trofi_Image_2043c85102.png'}
                      alt="trofi"
                    />
                  </Pulse>
                </div>
              </Col>
            </Row>
          </div>
          <div className="service_react_title text-center m-auto">
            <span style={{ display: "inline-block" }}>

              Technology Stack
              <div className='text-item' style={{ display: "flex", justifyContent: "center",padding:'5px 0 29px 0' }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginRight: "10px" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginLeft: "10px" }}> </div>
              </div>
            </span>
          </div>
          {/* <div>
        <div className="service_line1"></div>
      </div> */}
          {/* <div className="service_stack_content">
            {allTechnologyStacks.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_stack_group" key={i}>
                    <div className="service_stack_title_content">
                      <img src={imagePathURL + item.attributes?.image.data.attributes.url} alt="technology" />
                      <div className="service_stack_title">{item.attributes.title}</div>
                    </div>
                    <div className="service_arr_group">
                      {item.attributes.technologyName?.replace(/\[|\]/g, '').split(',').map((d, i) => {
                        return (
                          <div
                            className="service_quote_details"
                            style={{
                              margin: "auto",
                              display: "flex",
                              alignItems: "center",
                              fontSize: '14px'
                            }}
                            key={i}
                          >
                            <div className="service_bullets"></div>
                            {d}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div> */}
          {/* <div className="service_stack_content">
            {ionictechnologyStack.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_stack_group" key={i}>
                    <div className="service_stack_title_content">
                      <img src={item.image} alt="technology" />
                      <div className="service_stack_title">{item.title}</div>
                    </div>
                    <div className="service_arr_group">
                      {item.dataArr.map((technology, i) => (
                        <div className="service_quote_details" 
                        style={{
                          margin: "auto",
                          display: "flex",
                          alignItems: "center",
                          fontSize: '14px'
                        }} key={i} >
                          <div className="service_bullets"></div>
                          {technology}
                        </div>
                      ))}
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div> */}

          <div className='technology'>
            <div className='technologytab'>
              {ionictechnologyStack.map((stack, index) => (
                <ul className={`tabbar-ul ${selectedStack === index ? 'active' : ''}`} key={index}>
                  <li
                    className='tabbar-li'
                    onClick={() => handleStackClick(index)}
                  >
                    {stack.title}
                  </li>
                </ul>
              ))}
            </div>
            <hr />
            <div className='technologydetalis'>
              {/* {selectedStack && ( */}
              <div className='stackdata'>
                {ionictechnologyStack[selectedStack].dataArr.map((item, index) => (
                  <ul>
                    {/* <img src="https://admin.madhavtechno.com/uploads/Maintenance_48c82ee210.png" /> */}
                    <img src={item.image} alt={selectedStack.title} style={{ height: item.name === 'Cross-Platform' ? 75 : '', width: item.name === 'Cross-Platform' ? 80 :''}} />
                    <li key={index}>{item.name}</li>
                  </ul>
                ))}
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
        {/* <ServiceFooter /> */}
        <Footer />
        <Scrolltop />
      </Layout>
    </>
  )
}

// export const query = graphql`
//   {
//     allServices {
//       nodes {
//         data {
//           attributes {
//             description1
//             description2
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//             section1Desc
//             section1Title
//             section2ContentBtnTxt
//             section2ContentDesc
//             section2ContentImage {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//             section2ContentSubTitle
//             section2ContentTitle
//             section2Subtitle
//             section2Title
//             title
//           }
//         }
//       }
//     }

//     allServiceDevelopmentServices {
//       nodes {
//         data {
//           attributes {
//             description
//             title
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     allTechnologyStacks {
//       nodes {
//         data {
//           attributes {
//             technologyName
//             title
//             image {
//               data {
//                 attributes {
//                   url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     allGetInTouchs {
//       nodes {
//         data {
//           attributes {
//             title
//             description
//             btnTxt
//           }
//         }
//       }
//     }
//   }
// `;

export default Ionic